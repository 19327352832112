.home {
    --bd-purple: #4c0bce;
    --bd-violet: #712cf9;
    --bd-accent: #ffe484;
    --bd-violet-rgb: 112.520718,44.062154,249.437846;
    --bd-accent-rgb: 255,228,132;
    --bd-pink-rgb: 214,51,132;

    .bd-masthead {
        padding: 3rem 0;
        background-image: linear-gradient(180deg, rgba(var(--bs-body-bg-rgb), 0.01), rgba(var(--bs-body-bg-rgb), 1) 85%),radial-gradient(ellipse at top left, rgba(var(--bs-primary-rgb), 0.5), transparent 50%),radial-gradient(ellipse at top right, rgba(var(--bd-accent-rgb), 0.5), transparent 50%),radial-gradient(ellipse at center right, rgba(var(--bd-violet-rgb), 0.5), transparent 50%),radial-gradient(ellipse at center left, rgba(var(--bd-pink-rgb), 0.5), transparent 50%);
    }

    .btn-bd-primary {
        --bs-btn-font-weight: 600;
        --bs-btn-color: var(--bs-white);
        --bs-btn-bg: var(--bd-violet);
        --bs-btn-border-color: var(--bd-violet);
        --bs-btn-border-radius: .5rem;
        --bs-btn-hover-color: var(--bs-white);
        --bs-btn-hover-bg: #6528e0;
        --bs-btn-hover-border-color: #6528e0;
        --bs-btn-focus-shadow-rgb: var(--bd-violet-rgb);
        --bs-btn-active-color: var(--bs-btn-hover-color);
        --bs-btn-active-bg: #5a23c8;
        --bs-btn-active-border-color: #5a23c8;
    }
}

.navbar {
    @extend .navbar-dark;

    --bd-violet-rgb: 112.520718,44.062154,249.437846;
    
    padding: .75rem 0;
    background-color: transparent;
    background-image: linear-gradient(to bottom, rgba(var(--bd-violet-rgb), 1), rgba(var(--bd-violet-rgb), 0.95));
    box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.15),inset 0 -1px 0 rgba(0,0,0,0.15);
}

.text-violet {
    color: #6528e0;
}

.list {
    .card {
        transition: transform 0.2s ease-in-out;

        &:hover {
            transform: scale(1.01);
        }
    }
}

.navbar-brand {
    transition: transform 0.2s ease-in-out;

    &:hover {
        transform: rotate(-2deg) scale(1.1);
    }
}
